<template>
  <v-container class="h-screen flex items-center justify-center">
    <a-card style="width: 80%;">
      <span style="border-left: 4px blue solid;">&nbsp;&nbsp;找回密码</span>
      <a-form
          ref="formRef"
          :model="formState"
          v-bind="formItemLayout"
          style="width: 400px; height: 320px; margin:auto"
          class="mt-16"
      >
        <a-form-item label="注册手机号" name="username" :rules="formRules.username">
          <a-row :gutter="16">
            <a-col :span="14">
              <a-input v-model:value="formState.username"/>
            </a-col>
            <a-col :span="6">
              <a-button type="primary" @click="sendCaptcha" :disabled="sendDisabled">
                {{ sendButtonText }}
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label="验证码" name="captcha" :rules="formRules.captcha">
          <a-input v-model:value="formState.captcha" type="number"></a-input>
        </a-form-item>
        <a-form-item label="新密码" name="password" :rules="formRules.password">
          <a-input v-model:value="formState.password" type="password"></a-input>
        </a-form-item>
        <a-form-item label="确认密码" name="repassword" :rules="formRules.repassword">
          <a-input v-model:value="formState.repassword" type="password"></a-input>
        </a-form-item>
        <a-form-item :wrapper-col=" { span: 24, offset: 8 }">
          <a-button type="primary" @click="updatePassword">确认</a-button>
          <a-button class="ml-4">
            <router-link to="/login">取消</router-link>
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </v-container>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";
import { cloneDeep } from "lodash";
import { getCaptcha, resetPassword } from "@/api/auth";
import { message } from "ant-design-vue";
import { Rule } from "ant-design-vue/lib/form";

export default class ForgotPassword extends Vue {

  formItemLayout = {
    labelCol: {
      xs: {span: 24},
      md: {span: 8},
      sm: {span: 4},
    },
    wrapperCol: {
      xs: {span: 24},
      md: {span: 24},
      sm: {span: 24},
    },
  };

  // 表单1
  formState = {
    username: '',
    captcha: '',
    password: '',
    repassword: ''
  }

  validatePass2 = async (_rule: Rule, value: string) => {
    if (value !== this.formState.password) {
      return Promise.reject("两次输入的密码不匹配!");
    } else {
      return Promise.resolve();
    }
  };

  formRules = {
    username: [{required: true, message: '请输入手机号!'}, {
      type: 'number',
      message: '手机号格式不正确',
      trigger: 'blur',
      transform(value: any) {
        var regExp = /^(?:(?:\+|00)86)?1\d{10}$/
        if (value && !regExp.test(value)) {
          return false
        } else {
          return Number(value)
        }
      }
    }],
    captcha: [{required: true, message: '请输入验证码!'}],
    password: [
      {required: true, message: '请输入密码'},
      {
        trigger: 'blur',
        validator: (rule: any, value: any, callback: any) => {
          var passwordreg = /(?=.*\d)(?=.*[a-zA-Z]).{8,32}/
          if (!passwordreg.test(value)) {
            callback(new Error('请输入至少8位数字与字母混合的密码'))
          } else {
            callback()
          }
        }
      }
    ],
    repassword: [{required: true, message: '请再次输入密码!'}, {validator: this.validatePass2, trigger: 'change'}]
  }

  countDown = 60
  sendButtonText = "发送验证码"
  sendDisabled = false

  updatePassword() {
    this.$refs.formRef.validate().then((formData: any) => {
      const data = formData
      resetPassword(data).then((resp: any) => {
        if (resp.data === true) {
          message.success("密码找回成功!")
        } else {
          message.success("密码找回失败!")
        }
      })
    }, () => {
      message.error('请完善表单相关信息！');
    })
  }

  sendCaptcha() {
    console.log(this.formState.username)
    if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(this.formState.username)) {
      message.info('请输入正确的手机号');
    } else {
      this.sendDisabled = true
      let timerId = setInterval(() => {
        this.countDown--;
        this.sendButtonText = this.countDown + "秒后重试";
        if (this.countDown == 0) {
          clearInterval(timerId);
          this.sendButtonText = "重新发送";
          this.countDown = 60;
          this.sendDisabled = false;
        }
      }, 1000);
      const username = this.formState.username
      getCaptcha(username).then((resp => {
        if (resp.data === true) {
          message.success("验证码发送成功!")
        } else {
          message.success("验证码发送失败!")
        }
      }))
    }
  }
}
</script>
