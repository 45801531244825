import axios from "axios";

export function login(data: any): Promise<any> {
    return axios.post(`/api/auth/login`, data)
}

export function checkToken(): Promise<any> {
    return axios.get('/api/auth/check_token')
}

export function getCaptcha(username: string): Promise<any> {
    return axios.get(`/api/auth/captcha?username=${username}`)
}

export function resetPassword(data: any): Promise<any> {
    return axios.put('/api/auth/reset_password', data)
}


