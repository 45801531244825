import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import FuseLayout from '@/fuse-layouts/FuseLayout.vue'
import axios from "axios";
import { checkToken } from "@/api/auth";
import Login from "@/views/auth/Login.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import { message } from "ant-design-vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/app',
        name: '/app',
        component: FuseLayout,
        children: [
            {
                path: '/app/students',
                name: 'app-students',
                component: () => import('@/views/students/Students.vue')
            },
            {
                path: '/app/students/detail',
                name: 'app-students-detail',
                component: () => import('@/views/students/StudentDetail.vue')
            },
            {
                path: '/app/tasks',
                name: 'app-tasks',
                component: () => import('@/views/my/Tasks.vue')
            },
            {
                path: '/app/records',
                name: 'app-records',
                component: () => import('@/views/records/Records.vue')
            },
            {
                path: '/app/teachers',
                name: 'app-teachers',
                component: () => import('@/views/teachers/Teachers.vue')
            },
            {
                path: '/app/analytics-student',
                name: 'app-analytics-student',
                component: () => import('@/views/analytics/Student.vue')
            },
            {
                path: '/app/analytics-course',
                name: 'app-analytics-course',
                component: () => import('@/views/analytics/Course.vue')
            },
            {
                path: '/app/users',
                name: 'app-users',
                component: () => import('@/views/users/Users.vue')
            },
            {
                path: '/app/new-user',
                name: 'app-new-user',
                component: () => import('@/views/users/NewUser.vue')
            },
            {
                path: '/app/areas',
                name: 'app-areas',
                component: () => import('@/views/areas/Areas.vue')
            },
            {
                path: '/app/area/schools/view',
                name: 'app-area-schools-view',
                component: () => import('@/views/areas/AreaSchoolsView.vue')
            },
            {
                path: '/app/area/schools/edit',
                name: 'app-area-schools-edit',
                component: () => import('@/views/areas/AreaSchoolsEdit.vue')
            },
            {
                path: '/app/profile',
                name: 'app-my-profile',
                component: () => import('@/views/my/Profile.vue')
            }
        ]
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/forgot-password',
        component: ForgotPassword
    },
    {
        path: '/',
        redirect: '/app/students'
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const TOKEN_KEY = 'access-token'
router.beforeEach(async (to, from, next) => {
    if (to.path.startsWith('/login') || to.path.startsWith('/forgot-password')) {
        //取出token信息
        window.localStorage.removeItem(TOKEN_KEY)
        next()
    } else {
        //获取token的信息。
        const accessToken = window.localStorage.getItem(TOKEN_KEY)
        if (!accessToken) {
            next({path: '/login'})
        } else {
            //校验 token 合法性
            const checked = await checkToken()
            if (checked) {
                next()
            } else {
                message.error('会话校验失败，请重新登录!').then();
                next({path: '/login'})
            }
        }
    }
})

export default router
