<template>
  <v-navigation-drawer app width="280" elevation="1">
    <!-- -->
    <v-list
        nav
        active-color="primary"
        :items="items"
    >
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import navigationConfig from "@/fuse-layouts/layout1/navbar/navbar-config";
import { getMyProfile } from "@/api/my";
import { cloneDeep } from "lodash";

export default class Style1 extends Vue {
  items: any[] = []

  public mounted() {
    const staticMenu = cloneDeep(navigationConfig)
    this.items.push(...staticMenu)
    this.buildDynamicMenu()
  }

  buildDynamicMenu() {
    getMyProfile().then((resp: any) => {
      const profile = resp.data
      const roles: string[] = [profile.roles]
      if (roles.includes("super-admin") || roles.includes("education-admin") || roles.includes("area-admin")) {
        const settings: any = {
          value: 'settings',
          title: '系统管理',
          prependIcon: 'mdi-cog',
          $children: []
        }

        if (roles.includes("super-admin") || roles.includes("education-admin") || roles.includes("area-admin")) {
          settings.$children.push({
            value: 'upms-users',
            title: '管理员管理',
            to: '/app/users',
          })
        }

        if (roles.includes("super-admin") || roles.includes("education-admin")) {
          settings.$children.push({
            value: 'upms-areas',
            title: '动态属性配置',
            to: '/app/areas',
          })
        }

        if (roles.includes("super-admin")) {
          settings.$children.push({
            value: 'app-records',
            title: '导入记录'/*'导入导出记录'*/,
            to: '/app/records',
          })
        }
        this.items.push(settings)
      }
    })
  }
}
</script>
