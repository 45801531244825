const navigationConfig = [
    {
        value: 'students',
        title: '学员管理',
        prependIcon: 'mdi-account',
        to: '/app/students'
    },
    {
        value: 'calendar',
        title: '讲师管理',
        prependIcon: 'mdi-chair-rolling',
        to: '/app/teachers'
    },
    {
        value: 'analytics',
        title: '数据统计',
        prependIcon: 'mdi-google-analytics',
        $children: [
            {
                value: 'analytics-students',
                title: '注册/登录学生数据',
                to: '/app/analytics-student',
            },
            {
                value: 'analytics-teachers',
                title: '学生看课数据',
                to: '/app/analytics-course'
            }
        ],
    }
];

export default navigationConfig
