import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    app: "",
    width: "280",
    elevation: "1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list, {
        nav: "",
        "active-color": "primary",
        items: _ctx.items
      }, null, 8, ["items"])
    ]),
    _: 1
  }))
}