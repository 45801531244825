<template>
  <Layout2></Layout2>
</template>
<script>
import {Options, Vue} from "vue-class-component";
import Layout1 from '@/fuse-layouts/layout1/Layout1'
import Layout2 from '@/fuse-layouts/layout2/Layout2'

@Options({
  components: {
    Layout2
  }
})
export default class FuseLayout extends Vue {
}
</script>
