// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

// Vuetify components
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// MDI
// import { aliases, mdi } from 'vuetify/lib/iconsets/mdi'

const myCustomLightTheme = {
    dark: false,
    colors: {
        background: '#EFF1F5',
        surface: '#FFFFFF',
        primary: '#0052D9',
        'primary-darken-1': '#0052D9',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
}


export default createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
        }
    },
    icons: {
        defaultSet: 'mdi',
        // aliases,
        sets: {
            // mdi,
        }
    },
})
