export default {
  "message": {
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你好，世界！"])}
  },
  "commons": {
    "agGrid": {
      "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Select All)"])},
      "selectAllSearchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Select All Search Results)"])},
      "searchOoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
      "blanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Blanks)"])},
      "noMatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matches"])},
      "filterOoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter..."])},
      "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equals"])},
      "notEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not equal"])},
      "blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blank"])},
      "notBlank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not blank"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose One"])},
      "lessThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than"])},
      "greaterThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greater than"])},
      "lessThanOrEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than or equal"])},
      "greaterThanOrEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greater than or equal"])},
      "inRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In range"])},
      "inRangeStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
      "inRangeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains"])},
      "notContains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not contains"])},
      "startsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts with"])},
      "endsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends with"])},
      "dateFormatOoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY-MM-DD"])},
      "andCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AND"])},
      "orCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR"])},
      "applyFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
      "resetFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
      "cancelFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "textFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Filter"])},
      "numberFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Filter"])},
      "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Filter"])},
      "setFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Filter"])},
      "columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "pivotMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pivot Mode"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Groups"])},
      "rowGroupColumnsEmptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag here to set row groups"])},
      "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values"])},
      "valueColumnsEmptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag here to aggregate"])},
      "pivots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Labels"])},
      "pivotColumnsEmptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag here to set column labels"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "rowDragRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rows"])},
      "loadingOoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
      "noRowsToShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Rows To Show"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
      "pinColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Column"])},
      "pinLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Left"])},
      "pinRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Right"])},
      "noPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Pin"])},
      "valueAggregation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Aggregation"])},
      "autosizeThiscolumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autosize This Column"])},
      "autosizeAllColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autosize All Columns"])},
      "groupBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group by"])},
      "ungroupBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un-Group by"])},
      "addToValues": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add $", _interpolate(_named("variable")), " to values"])},
      "removeFromValues": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove $", _interpolate(_named("variable")), " from values"])},
      "addToLabels": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add $", _interpolate(_named("variable")), " to labels"])},
      "removeFromLabels": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove $", _interpolate(_named("variable")), " from labels"])},
      "resetColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Columns"])},
      "expandAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand All"])},
      "collapseAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close All"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
      "ctrlC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ctrl+C"])},
      "copyWithHeaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy With Headers"])},
      "copyWithHeaderGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy With Header Groups"])},
      "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste"])},
      "ctrlV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ctrl+V"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "csvExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV Export"])},
      "excelExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Export"])},
      "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
      "avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
      "filteredRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtered"])},
      "selectedRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
      "totalRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Rows"])},
      "totalAndFilteredRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows"])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
      "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
      "nextPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Page"])},
      "lastPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Page"])},
      "firstPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Page"])},
      "previousPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Page"])},
      "pivotColumnGroupTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "pivotChartAndPivotMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pivot Chart & Pivot Mode"])},
      "pivotChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pivot Chart"])},
      "chartRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart Range"])},
      "columnChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column"])},
      "groupedColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouped"])},
      "stackedColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stacked"])},
      "normalizedColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Stacked"])},
      "barChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
      "groupedBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouped"])},
      "stackedBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stacked"])},
      "normalizedBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Stacked"])},
      "pieChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pie"])},
      "pie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pie"])},
      "doughnut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doughnut"])},
      "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line"])},
      "xyChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X Y (Scatter)"])},
      "scatter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scatter"])},
      "bubble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bubble"])},
      "areaChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
      "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
      "stackedArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stacked"])},
      "normalizedArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Stacked"])},
      "histogramChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histogram"])},
      "combinationChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination"])},
      "columnLineCombo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column & Line"])},
      "AreaColumnCombo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area & Column"])},
      "pivotChartTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pivot Chart"])},
      "rangeChartTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Chart"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "defaultCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(None)"])},
      "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
      "xyValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X Y Values"])},
      "paired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paired Mode"])},
      "axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Axis"])},
      "navigator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigator"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
      "thickness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thickness"])},
      "xType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X Type"])},
      "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "xRotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X Rotation"])},
      "yRotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y Rotation"])},
      "ticks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticks"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
      "padding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padding"])},
      "spacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spacing"])},
      "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "titlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart title - double click to edit"])},
      "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
      "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])},
      "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])},
      "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottom"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left"])},
      "labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
      "minSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Size"])},
      "maxSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Size"])},
      "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legend"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "markerSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marker Size"])},
      "markerStroke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marker Stroke"])},
      "markerPadding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marker Padding"])},
      "itemSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Spacing"])},
      "itemPaddingX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Padding X"])},
      "itemPaddingY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Padding Y"])},
      "layoutHorizontalSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal Spacing"])},
      "layoutVerticalSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical Spacing"])},
      "strokeWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stroke Width"])},
      "offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset"])},
      "offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offsets"])},
      "tooltips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tooltips"])},
      "callout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callout"])},
      "markers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markers"])},
      "shadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadow"])},
      "blur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blur"])},
      "xOffset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X Offset"])},
      "yOffset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y Offset"])},
      "lineWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Width"])},
      "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
      "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold"])},
      "italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italic"])},
      "boldItalic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold Italic"])},
      "predefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predefined"])},
      "fillOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill Opacity"])},
      "strokeOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Opacity"])},
      "histogramBinCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bin count"])},
      "columnGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column"])},
      "barGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
      "pieGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pie"])},
      "lineGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line"])},
      "scatterGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X Y (Scatter)"])},
      "areaGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
      "histogramGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histogram"])},
      "combinationGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination"])},
      "groupedColumnTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouped"])},
      "stackedColumnTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stacked"])},
      "normalizedColumnTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Stacked"])},
      "groupedBarTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouped"])},
      "stackedBarTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stacked"])},
      "normalizedBarTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Stacked"])},
      "pieTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pie"])},
      "doughnutTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doughnut"])},
      "lineTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line"])},
      "groupedAreaTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
      "stackedAreaTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stacked"])},
      "normalizedAreaTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Stacked"])},
      "scatterTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scatter"])},
      "bubbleTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bubble"])},
      "histogramTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histogram"])},
      "columnLineComboTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column & Line"])},
      "areaColumnComboTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area & Column"])},
      "customComboTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Combination"])},
      "noDataToChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available to be charted."])},
      "pivotChartRequiresPivotMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pivot Chart requires Pivot Mode enabled."])},
      "chartSettingsToolbarTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
      "chartLinkToolbarTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked to Grid"])},
      "chartUnlinkToolbarTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlinked from Grid"])},
      "chartDownloadToolbarTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Chart"])},
      "seriesChartType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series Chart Type"])},
      "seriesType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series Type"])},
      "secondaryAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Axis"])},
      "ariaHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hidden"])},
      "ariaVisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visible"])},
      "ariaChecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checked"])},
      "ariaUnchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unchecked"])},
      "ariaIndeterminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indeterminate"])},
      "ariaDefaultListName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
      "ariaColumnSelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle Select All Columns"])},
      "ariaInputEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Editor"])},
      "ariaDateFilterInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Filter Input"])},
      "ariaFilterList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter List"])},
      "ariaFilterInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Input"])},
      "ariaFilterColumnsInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Columns Input"])},
      "ariaFilterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Value"])},
      "ariaFilterFromValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter from value"])},
      "ariaFilterToValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter to value"])},
      "ariaFilteringOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtering Operator"])},
      "ariaColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column"])},
      "ariaColumnList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column List"])},
      "ariaColumnGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Group"])},
      "ariaRowSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press SPACE to select this row"])},
      "ariaRowDeselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press SPACE to deselect this row"])},
      "ariaRowToggleSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Space to toggle row selection"])},
      "ariaRowSelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Space to toggle all rows selection"])},
      "ariaToggleVisibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press SPACE to toggle visibility"])},
      "ariaSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "ariaSearchFilterValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search filter values"])},
      "ariaRowGroupDropZonePanelLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Groups"])},
      "ariaValuesDropZonePanelLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values"])},
      "ariaPivotDropZonePanelLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Labels"])},
      "ariaDropZoneColumnComponentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press DELETE to remove"])},
      "ariaDropZoneColumnValueItemDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press ENTER to change the aggregation type"])},
      "ariaLabelColumnMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Menu"])},
      "ariaLabelCellEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell Editor"])},
      "ariaLabelDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog"])},
      "ariaLabelSelectField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Field"])},
      "ariaLabelTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tooltip"])},
      "ariaLabelContextMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context Menu"])},
      "ariaLabelSubMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SubMenu"])},
      "ariaLabelAggregationFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggregation Function"])},
      "thousandSeparator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([","])},
      "decimalSeparator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])}
    }
  },
  "apps": {
    "e-learning": {
      "students": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学生"])},
        "actions": {
          "newStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增学生"])},
          "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导入"])},
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传文件"])},
          "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载模版"])},
          "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])}
        },
        "table": {
          "header": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
            "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性别"])},
            "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
            "birthplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生地"])},
            "nativePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["籍贯"])},
            "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["民族"])},
            "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家/地区"])},
            "identityCardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证件类型"])},
            "identityCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证件号"])},
            "politicalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["政治面貌"])},
            "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康状况"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学校"])},
            "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年级"])},
            "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["班级"])}
          }
        }
      },
      "studentDetail": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学生详情"])}
      },
      "teachers": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教师"])},
        "actions": {
          "new-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增教师"])}
        },
        "table": {
          "header": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
            "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性别"])},
            "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业"])},
            "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教龄"])},
            "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["职称"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学校"])},
            "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
            "nativePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生地"])},
            "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["民族"])},
            "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家/地区"])},
            "identityCardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["省份证类型"])},
            "identityCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证号"])},
            "politicalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["政治面貌"])}
          }
        }
      }
    },
    "accessControls": {
      "rbac": {
        "newUser": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建用户"])},
          "form": {
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
            "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
            "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否启用"])}
          },
          "actions": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])}
          },
          "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*表示为必填字段"])}
        },
        "editUser": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑用户"])},
          "form": {
            "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户ID"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
            "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
            "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否启用"])}
          },
          "actions": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用"])}
          },
          "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*表示为必填字段"])}
        },
        "users": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
          "actions": {
            "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建用户"])}
          }
        }
      }
    }
  }
}