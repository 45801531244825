import { createApp } from 'vue'
import App from './App.vue'
import './styles/index.scss'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import "./mock/index"
import VueAxios from 'vue-axios'
import axios, { AxiosError } from "axios";
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import Antd, { message } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import moment from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')

import { loadFonts } from './plugins/webfontloader'

loadFonts().then()

function tokenInterceptor() {
    // 基本请求公共路径
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    // 超时时间
    axios.defaults.timeout = 360000
    // 请求拦截器
    axios.interceptors.request.use((config: any) => {
        const TOKEN_KEY = 'access-token'
        const tokenString = window.localStorage.getItem(TOKEN_KEY)
        tokenString && (config.headers.Authorization = `${tokenString}`)
        config.withCredentials = true;
        return config
    }, error => {
        return Promise.reject(error)
    })
    // 响应拦截器
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        console.log(error.code)
        if (error.code === AxiosError.ERR_NETWORK) {
            message.error('服务器连接错误，请检查网络连接!').then();
            return Promise.reject(error);
        }
        switch (error.response.status) {
            case 401:
                message.error('登录状态过期,请重新登录!').then();
                break;
            case 404:
                break;
            case 400:
                // message.error('客户端请求有语法错误，不能被服务器所理解');
                message.error(error.response.data.msg).then();
                break;
            case 403:
                message.error('权限不足,请联系管理员!').then();
                break;
            case 500:
                message.error('服务器发生不可预期的错误').then();
                break;
            case 504:
            case 505:
            default:
                message.error('未知错误!').then();
                break;
        }
        return Promise.reject(error);
    })
}

tokenInterceptor()

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(vuetify)
app.use(Antd)
app.use(i18n)
app.mount('#app')
