import axios from "axios";

export function getMyAreas(): Promise<any> {
    return axios.get(`/api/my/areas`)
}

export function getMySchools(): Promise<any> {
    return axios.get(`/api/my/schools`)
}

export function getMyGrades(): Promise<any> {
    return axios.get(`/api/my/grades`)
}

export function getMyStages(): Promise<any> {
    return axios.get(`/api/my/stages`)
}

export function getMyRanks(): Promise<any> {
    return axios.get(`/api/my/ranks`)
}

export function getMyTerms(): Promise<any> {
    return axios.get(`/api/my/terms`)
}

export function getMySubjects(): Promise<any> {
    return axios.get(`/api/my/subjects`)
}

export function getMyCourses(): Promise<any> {
    return axios.get(`/api/my/courses`)
}

export function getMyProfile(): Promise<any> {
    return axios.get(`/api/my/profile`)
}

export function updateMyPassword(data: any): Promise<any> {
    return axios.put(`/api/my/modify_password`, data)
}

export function getMyCaptcha(username:string): Promise<any> {
    return axios.get(`/api/my/captcha?username=${username}`)
}

//
export function createImportTask(data: FormData): Promise<any> {
    return axios.post(`/api/my/import`, data)
}

export function createExportTask(params: any): Promise<any> {
    return axios.post(`/api/my/export`, params)
}

export function getMyTasks(type: string, page: number, size: number): Promise<any> {
    return axios.get(`/api/my/tasks?type=${type}&page=${page}&size=${size}`)
}


