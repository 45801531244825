<template>
  <!-- App.vue -->
  <v-app>
    <Style1></Style1>
    <v-app-bar app elevation="1">
      <!-- -->
      <v-app-bar-nav-icon icon="mdi-backburger" variant="text"></v-app-bar-nav-icon>
      <v-toolbar-title>
      </v-toolbar-title>
      <!--      <v-spacer></v-spacer>
            <v-menu :close-on-content-click="true">
              <template v-slot:activator="{ props }">
                <a-button v-bind="props" icon="mdi-web">
                  {{ $t('message.language') }}
                </a-button>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item) in languages"
                    :key="item"
                    :value="item"
                    @click="changeLanguage(item)"
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <a-button variant="text" icon="mdi-fullscreen"></a-button>
            <a-button variant="text" icon="mdi-format-size"></a-button>
            <a-button variant="text" icon="mdi-magnify"></a-button>
            <a-button variant="text" icon="mdi-tag-multiple-outline"></a-button>
            <a-button variant="text" icon="mdi-alert-circle-outline"></a-button>-->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <a-button size="large" rounded v-bind="props">
              <v-avatar size="small" rounded>
                <v-img src="/../img/avatars/profile.jpg" alt="John"></v-img>
              </v-avatar>
              <div class="ml-2">
                <div class="text-body-1">{{ user?.name }}</div>
                <div class="text-caption">{{ user?.roleName }}</div>
              </div>
            </a-button>
          </template>
          <v-list min-width="200px">
            <v-list-item to="/app/profile">
              <v-list-item-title>个人信息</v-list-item-title>
            </v-list-item>
            <v-list-item to="/login">
              <v-list-item-title>注销</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->
    <v-main style="height:100%">
      <!-- If using vue-router -->
      <router-view></router-view>
    </v-main>
    <!--    <v-footer app>
          &lt;!&ndash; &ndash;&gt;
          Footer
        </v-footer>-->
  </v-app>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Style1 from "./navbar/style1/Style1.vue";
import { getMyProfile } from "@/api/my";

@Options({
  components: {
    Style1
  }
})
export default class Layout1 extends Vue {
  languages = ['zh', 'en']
  menu = false

  changeLanguage(language: string) {
    this.$i18n.locale = language
  }

  user: any = {}

  mounted() {
    this.init()
  }

  init() {
    getMyProfile().then((resp: any) => {
      const profile = resp.data
      this.user.name = profile.name
      this.user.username = profile.username
      this.user.roles = profile.roles
      this.user.roleName = this.user.roles == "super-admin" ? '超级管理员'
          : this.user.roles == "education-admin" ? '教科院管理员'
              : this.user.roles == "area-admin" ? '区域管理员'
                  : this.user.roles == "school-admin" ? '学校管理员' : ''
    })
  }

}
</script>
