<template>
  <div class="h-screen flex items-center justify-center">
    <a-card>
      <a-card-text class="flex flex-row">
        <div class="flex-grow  pl-24 pr-24 pt-12 pb-12 items-center justify-center">
          <v-img src="/../img/login/left.png" width="320px" alt="Login"></v-img>
        </div>
        <a-divider type="vertical" style="height: 380px; background-color: #e8e8e8"></a-divider>
        <div class="flex-none pl-24 pr-24 pt-12 pb-12">
          <a-form
              ref="formRef"
              :model="formState"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              autocomplete="off"
              style="width: 320px"
          >
            <div class="text-h4 mb-8 text-blue-darken-4 font-weight-bold text-center" style="color:#0052D9">区域管理员平台
            </div>
            <a-form-item label="手机号" name="username" :rules="formRules.username">
              <a-input v-model:value="formState.username"></a-input>
            </a-form-item>
            <a-form-item label="密码" name="password" :rules="formRules.password">
              <a-input-password v-model:value="formState.password"></a-input-password>
            </a-form-item>
            <div class="text-right mb-2">
              <a-button type="link">
                <router-link to="/forgot-password">忘记密码?</router-link>
              </a-button>
            </div>
            <a-form-item :wrapper-col="{ span: 18, offset: 6 }">
              <!--              <a-button type="primary" html-type="submit">Submit</a-button>-->
              <a-button html-type="submit" type="primary" :loading="loading" @click="login" block>登录</a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-card-text>
    </a-card>
  </div>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";
import { login } from "@/api/auth";
import { cloneDeep } from "lodash";
import { message } from "ant-design-vue";

export default class Login extends Vue {
  valid = true

  formState = {
    username: '',
    password: ''
  }

  formRules = {
    username: [{required: true, message: '请输入用户名!'}, {
      type: 'number',
      message: '手机号格式不正确',
      trigger: 'blur',
      transform(value: any) {
        var regExp = /^(?:(?:\+|00)86)?1\d{10}$/
        if (value && !regExp.test(value)) {
          return false
        } else {
          return Number(value)
        }
      }
    }],
    password: [{required: true, message: '请输入密码!'}]
  }

  loading = false

  // handleFinish = (values: FormState) => {
  //   console.log(values, formState);
  // };
  // handleFinishFailed = errors => {
  //   console.log(errors);
  // };
  // resetForm = () => {
  //   this.formRef.value.resetFields();
  // };
  // handleValidate = (...args) => {
  //   console.log(args);
  // };

  login() {
    this.$refs.formRef.validate().then((formData: any) => {
      console.log(formData)
      login(formData).then((resp: any) => {
        if (resp.data) {
          window.localStorage.setItem("access-token", resp.data)
          message.success("登录成功!")
          this.$router.replace({path: '/'})
        } else {
          message.error("登录失败!")
        }
      }, () => {
        console.log("")
      })
    }, () => {
      message.error('请完善表单相关信息！');
    });
  }
}

</script>
