<template>
  <a-layout class="h-screen">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible style="background: #fff">
      <div class="logo text-h6"><span style="color:#0052D9;line-height:32px;margin-left: 12px;">区域管理员平台</span></div>
      <v-divider></v-divider>
      <a-menu
          v-model:selectedKeys="selectedKeys"
          @click="menuClick"
          :theme="theme"
          :mode="mode">
        <a-menu-item key="/app/students">
          <template #icon>
            <UserOutlined/>
          </template>
          <span>学员管理</span>
        </a-menu-item>
        <a-menu-item key="/app/teachers">
          <template #icon>
            <UserOutlined/>
          </template>
          <span>讲师管理</span>
        </a-menu-item>
        <a-sub-menu key="sub1">
          <template #icon>
            <BarChartOutlined/>
          </template>
          <template #title>数据统计</template>
          <a-menu-item key="/app/analytics-student">注册登录学生数据</a-menu-item>
          <a-menu-item key="/app/analytics-course">学生看课数据</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub2"
                    v-if="user.roles==='super-admin' || user.roles==='education-admin' || user.roles==='area-admin'">
          <template #icon>
            <SettingOutlined/>
          </template>
          <template #title>系统管理</template>
          <a-menu-item key="/app/users"
                       v-if="user.roles==='super-admin' || user.roles==='education-admin' || user.roles==='area-admin'">
            管理员管理
          </a-menu-item>
          <a-menu-item key="/app/areas"
                       v-if="user.roles==='super-admin'">
            动态属性配置
          </a-menu-item>
          <a-menu-item key="/app/records"
                       v-if="user.roles==='super-admin'">
            导入<!--导出-->记录
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff">
        <div class="flex justify-space-between">
          <div>
<!--            <MenuFoldOutlined
                v-if="collapsed"
                class="trigger"
                @click="() => (collapsed = !collapsed)"
            />
            <MenuUnfoldOutlined v-else class="trigger" @click="() => (collapsed = !collapsed)"/>-->
          </div>
          <div>
            <a-avatar>
              <template #icon>
                <UserOutlined/>
              </template>
            </a-avatar>
            <span class="ml-2">{{ user.name }}</span>
            <a-dropdown class="ml-2" :trigger="['click']">
              <a class="ant-dropdown-link" @click.prevent>
                {{ user.roleName }}
                <DownOutlined/>
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0" @click="menuClick({key:'/app/profile'})">
                    个人信息
                  </a-menu-item>
                  <a-menu-divider/>
                  <a-menu-item key="3" @click="menuClick({key:'/login'})">注销</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
      </a-layout-header>
      <a-layout-content
          :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px', overflow:'scroll' }"
      >
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script lang="ts">
import {
  UserOutlined,
  BarChartOutlined,
  SettingOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons-vue';
import { Options, Vue } from "vue-class-component";
import { MenuMode, MenuTheme } from "ant-design-vue";
import { getMyProfile } from "@/api/my";

@Options({
  components: {
    UserOutlined,
    BarChartOutlined,
    SettingOutlined,
    DownOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined
  }
})
export default class Layout2 extends Vue {
  selectedKeys = ['1']
  collapsed = false

  mode: MenuMode = 'inline'
  theme: MenuTheme = 'light'

  user: any = {}

  mounted() {
    this.init()
  }

  init() {
    getMyProfile().then((resp: any) => {
      const profile = resp.data
      this.user.name = profile.name
      this.user.username = profile.username
      this.user.roles = profile.roles
      this.user.roleName = this.user.roles == "super-admin" ? '超级管理员'
          : this.user.roles == "education-admin" ? '教科院管理员'
              : this.user.roles == "area-admin" ? '区域管理员'
                  : this.user.roles == "school-admin" ? '学校管理员' : ''
    })
  }

  menuClick(menu: any) {
    this.$router.push({path: menu.key})
  }

}
</script>
<style>
/*#components-layout-demo-custom-trigger .trigger {*/
/*  font-size: 18px;*/
/*  line-height: 64px;*/
/*  padding: 0 24px;*/
/*  cursor: pointer;*/
/*  transition: color 0.3s;*/
/*}*/

/*#components-layout-demo-custom-trigger .trigger:hover {*/
/*  color: #1890ff;*/
/*}*/

.ant-layout .logo {
  height: 32px;
  margin: 16px;
  font-weight: bold;
}

/*.site-layout .site-layout-background {*/
/*  background: #fff;*/
/*}*/
</style>
