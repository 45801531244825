export default {
  "message": {
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello world!"])}
  },
  "apps": {
    "e-learning": {
      "students": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
        "actions": {
          "newStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Student"])},
          "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
          "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Template"])},
          "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
        },
        "table": {
          "header": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
            "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
            "birthplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthplace"])},
            "native-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native Place"])},
            "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naion"])},
            "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
            "identity-card-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Type"])},
            "identity-card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID No."])},
            "political-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Political Status"])},
            "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
            "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
            "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])}
          }
        }
      },
      "studentDetail": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Detail"])}
      },
      "teachers": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
        "actions": {
          "new-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Teacher"])}
        },
        "table": {
          "header": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
            "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
            "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
            "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
            "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
            "native-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native Place"])},
            "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nation"])},
            "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country/Region"])},
            "identity-card-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Type"])},
            "identity-card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID No."])},
            "politicalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Political Status"])}
          }
        }
      }
    },
    "accessControls": {
      "rbac": {
        "newUser": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User"])},
          "form": {
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
            "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
            "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])}
          },
          "actions": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
          },
          "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indicates required field"])}
        },
        "editUser": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
          "form": {
            "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
            "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
            "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])}
          },
          "actions": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])}
          },
          "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indicates required field"])}
        },
        "users": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
          "actions": {
            "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User"])}
          }
        }
      }
    }
  }
}